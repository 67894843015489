<template>
  <div id="bank_add_app">
      <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="$router.go(-1)"/>
      <div class="title">
          <h5>添加银行卡</h5>
          <span>请添加持卡人本人的银行卡</span>
      </div>
      <van-action-sheet
        v-model="show"
        :actions="actions"
        cancel-text="取消"
        @select='onActionSelect'
        close-on-click-action
        @cancel="show=false"
      />
      <van-dialog v-model="smsshow" title="短信验证" @confirm='beforeClose' show-cancel-button>
          <van-field
            v-model="smsCode"
            center
            clearable
            label="短信验证码"
            placeholder="请输入短信验证码">
            </van-field>
      </van-dialog>
      <div class="bank_body">
        <van-form validate-first @submit="onSubmit">
            <van-divider  />
            <van-cell-group>
                <van-field v-model="cardForm.name" label="姓名" :rules="[{ required:true, message: '',trigger:'onBlur' }]" placeholder="请输入持卡人姓名" />
                <van-field v-model="cardForm.cardNumber" type="digit"
                    label="卡号" placeholder="持卡人本人银行卡号" :rules="[{ validator: CheckBankNo, message: error_msg,trigger:'onBlur' }]" />
                <van-field
                    v-model="cardForm.phone"
                    label="手机号码"
                    :rules="[{ validator:$util.checkPhone, message: '手机号码格式错误' }]"
                    placeholder="银行卡预留手机号码"/>
                <van-field
                    v-model="cardForm.identityTypeText"
                    label="证件类型"
                    maxlength='10'
                    readonly
                    @click="show=true"
                    placeholder="请选择您的证件类型"/>
                <van-field
                    v-model="cardForm.identityNo"
                    label="身份证号码"
                    :rules="[{ validator:$util.IdentityCodeValid, message: '身份证号码格式错误' }]"
                    placeholder="身份证号码"/>
                <van-field
                    v-model="cardForm.branchBankName"
                    label="支行名称"
                    maxlength="30"
                    placeholder="请输入支行名称"/>
            </van-cell-group>
             <div class="bank_bottom_div">
                <van-button block size="small" native-type="submit" type="primary">下一步</van-button>
            </div>
        </van-form>
      </div>
  </div>
</template>
<script>
import { Divider,CellGroup,Cell,Field,Button,Form,ActionSheet,NavBar,Dialog } from "vant";
import {allinpayUser} from '../../store/allinpay'
import {confirmBindBankCard,SignContract,applyBindBankCard} from  '../../api/common'
export default {    
    components:{
         [Divider.name]: Divider,
         [CellGroup.name]: CellGroup,
         [Cell.name]: Cell,
         [Field.name]: Field,
         [Button.name]: Button,
         [Form.name]: Form,
         [NavBar.name]: NavBar,
         [ActionSheet.name]: ActionSheet,
         [Dialog.Component.name]:Dialog.Component
    },
    data(){
        return {
            show:false,
            smsshow:false,
            actions:[],
            smsCode:'',
            applyData:'',
            cardForm:{
                name:'',
                cardNumber:'',
                phone:'',
                identityNo:'',
                identityType:'',
                identityTypeText:'',
                branchBankName:''
            },
            error_msg:'银行卡格式错误',
            firstAdd:false
        };
    },
    methods:{
        beforeClose() {
            var body,user=allinpayUser.get();
            if(!this.smsCode){
                this.$toast.fail('请输入短信验证码');
                return
            }
            if(this.scrapUserId){
                body = {
                    verificationCode:this.smsCode,
                    userId:this.scrapUserId,
                    userSource:2,
                    tranceNum:this.applyData.traceNum,
                    phone:this.cardForm.phone,
                };
            }else{
                console.log('applyData',this.applyData);
                body = {
                    verificationCode:this.smsCode,
                    userId:user.userId,
                    userSource:user.userSource,
                    tranceNum:this.applyData.traceNum,
                    phone:this.cardForm.phone,
                };
            }
            this.$toast.loading({duration: 0,forbidClick: true,message: '绑定银行卡中'});
            confirmBindBankCard(body).then(res=>{
                this.$toast.clear();
                this.$toast({type:res.status?'success':'fail',message:res.msg||res.message});
                if(this.firstAdd){
                    this.$router.replace('/magent/business');
                    return
                }
                if(this.scrapUserId){
                    if(res.status){
                        this.$router.go(-1);
                    }
                }else{
                    if(res.status&&!user.hasSign){
                        var signbody = {
                            "userId":user.userId,
                            "userSource": user.userSource,
                            "jumpPageType": 1,
                            "backUrl":process.env.VUE_APP_PAY_NOTIFY_URL,
                            "jumpUrl": window.location.origin+'/bank/wallet',
                            "noContractUrl": window.location.origin+'/bank/wallet',
                            "source": user.source
                        };
                        this.$toast.loading({duration: 0,forbidClick: true,message: '获取电子合约'});
                        SignContract(signbody).then(res=>{
                            if(res.status){
                                window.location.href=res.data.url;
                            }else{
                                this.$toast.clear();
                                this.$toast.fail("请求电子合约失败！");
                            }
                        }).catch(()=>{
                            this.$toast.clear();
                        });
                    }else if(res.status){
                        res.status&&this.$router.push('/bank/wallet');
                    }
                }
            }).catch(()=>{
                this.$toast.clear();
            })
        },
        onActionSelect(val){
            this.cardForm.identityType = val.id;
            this.cardForm.identityTypeText = val.name;
        },
        onSubmit(){
            var body;
            var user=allinpayUser.get();
            body = {
                "userId": user.userId,
                "userSource": user.userSource,
                "cardNo": this.cardForm.cardNumber,
                "phone": this.cardForm.phone,
                "name": this.cardForm.name,
                "cardCheck": 7,
                "identityType": this.cardForm.identityType,
                "identityNo": this.cardForm.identityNo,
                "branchBankName":this.cardForm.branchBankName
            };
            this.$toast.loading({duration: 0,forbidClick: true,message: '发送短信'});
            applyBindBankCard(body).then(res=>{
                this.$toast.clear();
                this.$toast({type:res.status?'success':'fail',message:res.msg||res.message});
                if(res.status){
                    this.applyData=res.data;
                    console.log('data',res.data);
                    console.log('applydata',this.applyData);
                    this.smsshow = true;
                }
            }).catch(()=>{
                this.$toast.clear();
            })
        },
        luhnCheck(bankno){
            var lastNum=bankno.substr(bankno.length-1,1);//取出最后一位（与luhn进行比较）
        
            var first15Num=bankno.substr(0,bankno.length-1);//前15或18位
            var newArr=new Array();
            for(var i=first15Num.length-1;i>-1;i--){    //前15或18位倒序存进数组
                newArr.push(first15Num.substr(i,1));
            }
            var arrJiShu=new Array();  //奇数位*2的积 <9
            var arrJiShu2=new Array(); //奇数位*2的积 >9
            
            var arrOuShu=new Array();  //偶数位数组
            for(var j=0;j<newArr.length;j++){
                if((j+1)%2==1){//奇数位
                    if(parseInt(newArr[j])*2<9)
                    arrJiShu.push(parseInt(newArr[j])*2);
                    else
                    arrJiShu2.push(parseInt(newArr[j])*2);
                }
                else //偶数位
                arrOuShu.push(newArr[j]);
            }
            
            var jishu_child1=new Array();//奇数位*2 >9 的分割之后的数组个位数
            var jishu_child2=new Array();//奇数位*2 >9 的分割之后的数组十位数
            for(var h=0;h<arrJiShu2.length;h++){
                jishu_child1.push(parseInt(arrJiShu2[h])%10);
                jishu_child2.push(parseInt(arrJiShu2[h])/10);
            }        
            
            var sumJiShu=0; //奇数位*2 < 9 的数组之和
            var sumOuShu=0; //偶数位数组之和
            var sumJiShuChild1=0; //奇数位*2 >9 的分割之后的数组个位数之和
            var sumJiShuChild2=0; //奇数位*2 >9 的分割之后的数组十位数之和
            var sumTotal=0;
            for(var m=0;m<arrJiShu.length;m++){
                sumJiShu=sumJiShu+parseInt(arrJiShu[m]);
            }
            
            for(var n=0;n<arrOuShu.length;n++){
                sumOuShu=sumOuShu+parseInt(arrOuShu[n]);
            }
            
            for(var p=0;p<jishu_child1.length;p++){
                sumJiShuChild1=sumJiShuChild1+parseInt(jishu_child1[p]);
                sumJiShuChild2=sumJiShuChild2+parseInt(jishu_child2[p]);
            }      
            //计算总和
            sumTotal=parseInt(sumJiShu)+parseInt(sumOuShu)+parseInt(sumJiShuChild1)+parseInt(sumJiShuChild2);
            
            //计算luhn值
            var k= parseInt(sumTotal)%10==0?10:parseInt(sumTotal)%10;        
            var luhn= 10-k;
            
            if(lastNum==luhn){
                return true;
            }else{
                return false;
            }        
        },
        CheckBankNo(bankno) {
            console.log(bankno);
            bankno = bankno.replace(/\s/g,'');
            if(bankno == "") {
                return false;
            }
            if(bankno.length < 16 || bankno.length > 19) {
                return false;
            }
            var num = /^\d*$/;
            if(!num.exec(bankno)) {
                return false;
            }
            var strBin = "10,18,30,35,37,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,58,60,62,65,68,69,84,87,88,94,95,98,99";
            if(strBin.indexOf(bankno.substring(0, 2)) == -1) {
                return false;
            }
            if(!this.luhnCheck(bankno)){
                return false;
            }
            return true;
        }
    },
    created(){
        this.scrapUserId = this.$route.query.scrapUserId;
        this.firstAdd = !!this.$route.query.first;
        for(var i in  this.$enum.IdentityTypeEnum){
            this.actions.push({name:this.$enum.IdentityTypeEnum[i],id:i});
        }
        this.cardForm.identityNo = window.sessionStorage.getItem('userIdCard');
    }
}
</script>

<style scoped>
.bank_bottom_div{
    text-align: center;
    width: 40%;
    margin: 0px auto;
    margin-top:100px;
}
.bank_body{
    /* padding-top: 50px; */
    width: 90%;
    margin: 0px auto;
}
.title{
    padding-top: 10%;
    text-align: center;
    width: 80%;
    margin: 0px auto;
    margin-bottom: 50px;
}
.title span{
    font-size: 16px;
}
</style>